import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

export interface ExtendedUser extends User {
  isPaidSubscriber: boolean;
}

export const queryAuth = () =>
  new Promise<ExtendedUser | null>((resolve, reject) => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(
      auth,
      async (user) => {
        if (user) {
          try {
            const db = getFirestore();
            const userDocRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);

            let isPaidSubscriber = false;

            if (userDoc.exists()) {
              const userData = userDoc.data();
              isPaidSubscriber =
                userData?.subscription?.isPaidSubscriber || false;
            }

            // Return the user object with the isPaidSubscriber field
            resolve({ ...user, isPaidSubscriber } as ExtendedUser);
          } catch (error) {
            console.error('Error fetching user subscription status:', error);
            reject(error);
          }
        } else {
          resolve(null);
        }
      },
      reject
    );

    return unsubscribe; // Return the unsubscribe function to allow stopping the listener if needed
  });
