import React from 'react';
import {
  Box,
  Text,
  Heading,
  Link,
  UnorderedList,
  ListItem,
  List,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Box p={10} w="100svw" color="white">
      <Heading mb={4}>Privacy Policy</Heading>
      <Text mb={2}>Effective Date: 1 August 2024</Text>

      <Text mb={4}>
        Welcome to the Privacy Policy of AetherCode Pty Ltd. (“AetherCode,”
        “Tapdex,” “we,” “us,” or “our”). Tapdex provides a digital platform
        dedicated to Brazilian Jiu-Jitsu (BJJ) practitioners. Our platform
        allows users to explore, learn, and share BJJ techniques and sequences
        through a comprehensive database. This database includes detailed
        technique descriptions, instructional videos, and community-sourced
        reviews and contributions from registered members. Users can subscribe
        to our service for a monthly fee of US$4.95 (subject to change) to gain
        full access to the database search tool. Users can also log sequences to
        help grow the database and may earn a free month for every 25 sequences
        added and approved. We reserve the right to modify or remove this
        feature at any time. This Privacy Policy applies to the following
        services operated by AetherCode (collectively, the “Service”):
      </Text>

      <UnorderedList mb={4}>
        <ListItem>
          www.tapdex.io and other websites owned and/or operated by AetherCode
          that contain a link to this Privacy Policy (collectively, &ldquo;the
          Site&rdquo;);
        </ListItem>
        <ListItem>
          All mobile applications that contain a link to this Privacy Policy
          (the “Tapdex Apps”); and
        </ListItem>
        <ListItem>
          All services made available by AetherCode through the Site and the
          AetherCode Apps, collectively, the “Service”.
        </ListItem>
      </UnorderedList>

      <Text mb={4}>
        This Privacy Policy explains what Personal Data (defined below) we
        collect, how we use and share that data, and your choices concerning our
        data practices. This Privacy Policy constitutes an integral part of our
        Terms of Service, accessible at: {' '}
        <Link href="https://www.tapdex.io/terms" isExternal>
          https://www.tapdex.io/terms
        </Link>
        . Please read this Privacy Policy before using our Service or submitting
        any Personal Data to AetherCode and
        <Link href="#" isExternal>
          contact us
        </Link>
        if you have any questions.
      </Text>

      <Heading size="md" mb={2}>
        INFORMATION WE COLLECT
      </Heading>
      <Text mb={2}>
        When you contact us or interact with our Service, we collect information
        that alone or in combination with other information could be used to
        identify you (“Personal Data”) as follows:
      </Text>

      <UnorderedList mb={4}>
        <ListItem>
          Personal Data You Provide to Us:
          <UnorderedList>
            <ListItem>
              Account Data: When you sign up, some information is required to
              create an account on our Service, such as your name, username,
              email address, and mailing address.
            </ListItem>
            <ListItem>
              Payment Data: If you choose to purchase a subscription to our
              Service via credit card, debit card or other payment method, our
              third-party payments processor (Stripe, Inc. or “Stripe”) will
              process certain payment and billing information, such as billing
              address, payment card details and authentication features. That
              information is collected by Stripe directly, and we do not collect
              or store it.
            </ListItem>
            <ListItem>
              Social Media Data: We have pages (“Social Media Pages”) on social
              media sites such as Facebook, Instagram, Pinterest, Twitter, and
              LinkedIn (each a “Social Media Site”). The platforms that host our
              Social Media Pages or Social Media Features may provide us with
              aggregate information and analytics regarding the use of our
              Social Media Pages and Social Media Features.
            </ListItem>
            <ListItem>
              User-Generated Content: User-generated content, such as profile
              pictures, photos, images, videos, posts, comments, questions,
              messages, user interactions, and other content or information that
              you generate, transmit or otherwise make available on the Service,
              as well as associated metadata.
            </ListItem>
            <ListItem>
              Contacts Book Data: Our mobile applications may allow you to
              synchronize the numbers in your Contacts address book on a regular
              basis in order to identify any friends and/or relatives who use
              the Service. When you do so, we will ensure that the relevant data
              is encrypted and will not use it for any purpose other than
              matching your contacts to users of the Service.
            </ListItem>
            <ListItem>
              Additional Information: To help improve your experience or enable
              certain features of the Service, you may choose to provide us with
              additional information, such as your date of birth/age, height,
              weight, gender, profile picture, city of residence, community
              username, statistics on your BJJ training history, and your
              reviews and feedback on techniques.
            </ListItem>
          </UnorderedList>
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={5}>
        Information We Generate From Your Use of Our Service:
      </Heading>
      <Text mt={3}>
        When you visit, use, interact and register with the Service, we may
        automatically generate certain information about your visit, use,
        interactions or registration. For example, we may monitor the number of
        people that visit our Site or Tapdex Apps, peak hours of visits, which
        page(s) are visited on our Site or Tapdex Apps, the domains our visitors
        come from (e.g., google.com, yahoo.com, etc.) and which browsers people
        use to access and visit our Site (e.g., Firefox, Microsoft Internet
        Explorer, etc.), broad geographical information, and Site- and Tapdex
        App-navigation pattern. In particular, the following information is
        created and automatically logged in our systems:
      </Text>
      <UnorderedList mt={3} pl={5}>
        <ListItem>
          Log Data: Information that your browser automatically sends whenever
          you visit the Site or Tapdex Apps. Log data includes your IP address
          (so we understand which country you are connecting from when you visit
          the Site or Tapdex Apps), browser type and settings, the date and time
          of your request, and how you interacted with the Site or Tapdex Apps.
        </ListItem>
        <ListItem>
          Cookies Data: We use cookies and similar technologies to operate and
          administer our Site and Tapdex Apps, make it easier for you to use the
          Site and Tapdex Apps during future visits, and gather usage data on
          our Site and Tapdex Apps. For more information about the technologies
          used and information collected on our Site and Tapdex Apps, please
          refer to our Cookie Policy, which forms part of this Privacy Policy.
        </ListItem>
        <ListItem>
          Device Data: Includes the model of the device, operating system, and
          browser you are using. Information collected may depend on the type of
          device you use and its settings. We may also collect data from your
          device to estimate a variety of metrics like the time, frequency and
          duration of, and calories burned during, your activities for the
          purpose of calculating your Usage and Activity Data (described below).
        </ListItem>
        <ListItem>
          Usage and Activity Data: We collect information about how you use our
          Site and Tapdex Apps, such as the types of content that you view or
          engage with, the features you use, the actions you take, and the time,
          frequency and duration of, and calories burned during, your
          activities.
        </ListItem>
        <ListItem>
          Geolocation Data: If you have enabled location services for a Tapdex
          App on your mobile device or on your computer via the Site, we may
          collect your precise location information based on city, county,
          postcode, country, latitude and longitude. Tapdex may also derive your
          approximate location from the IP address of your computer or mobile
          device. Geolocation data is used for purposes of directing you to
          nearby BJJ gyms and offering other relevant content and services.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={5}>
        Information We Receive From Third Parties:
      </Heading>
      <Text mt={3}>
        If you elect to authenticate to our Service using your account on
        another service (such as a Facebook, Google, or Apple account), we may
        receive from the other service information like your external account
        name as well as other information you may choose to make available to us
        based on your external account settings, such as contact lists and
        profile photos (if any). You can stop sharing the information from the
        other service with us by removing our access to that other service.
      </Text>

      <Heading as="h2" size="md" mt={5}>
        How We Use Personal Data
      </Heading>
      <Text mt={3}>
        We use your Personal Data for a variety of purposes, which are listed in
        the table below. If we need to use your Personal Data for an unrelated
        purpose, we will update this Privacy Policy to explain any such new
        purpose. In addition, in respect of each of the purposes for which we
        use your Personal Data, privacy laws in certain jurisdictions require us
        to ensure that we have a ‘legal basis’ for that use. Most commonly, we
        will rely on one of the following legal bases:
      </Text>
      <List as="ol" styleType="decimal" mt={3}>
        <ListItem>
          Where we need to process your Personal Data to deliver our Service to
          you, or to otherwise perform our contractual obligations to you
          (“Contractual Necessity”).
        </ListItem>
        <ListItem>
          Where we need to process your Personal Data for our legitimate
          business interests, and your interests and fundamental rights do not
          override those interests (“Legitimate Interests”).
        </ListItem>
        <ListItem>
          Where we need to process your Personal Data to comply with a legal or
          regulatory obligation (“Compliance with Law”).
        </ListItem>
        <ListItem>
          Where we have your specific consent to carry out the processing for
          the purpose in question (“Consent”).
        </ListItem>
      </List>
      <Heading as="h2" size="md" mt={5}>
        We have set out below, in a table format, the various purposes and legal
        bases we rely on in respect of the processing of your Personal Data:
      </Heading>
      <Table variant="simple" mt={3}>
        <Thead>
          <Tr>
            <Th>Purpose</Th>
            <Th>Legal Basis</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              Account creation – to create and manage your account on the
              Service.
            </Td>
            <Td>Contractual Necessity</Td>
          </Tr>
          <Tr>
            <Td>
              Service delivery – to operate and deliver our Service to you.
            </Td>
            <Td>Contractual Necessity</Td>
          </Tr>
          <Tr>
            <Td>
              Lifeline Group – to communicate with your Lifeline Notification
              Group regarding your whereabouts as described on our Service.
            </Td>
            <Td>Contractual Necessity</Td>
          </Tr>
          <Tr>
            <Td>
              Payments processing – to process your payments via our third-party
              payments processor.
            </Td>
            <Td>Contractual Necessity</Td>
          </Tr>
          <Tr>
            <Td>
              Customer support – to deliver customer support to you and respond
              to your queries.
            </Td>
            <Td>Contractual Necessity</Td>
          </Tr>
          <Tr>
            <Td>
              Contacts syncing – to match the phone numbers in your Contacts
              address book to users of the Service, if you have chosen to do so.
            </Td>
            <Td>Contractual Necessity</Td>
          </Tr>
          <Tr>
            <Td>
              Service improvement – to analyze how you use our Service and to
              improve it.
            </Td>
            <Td>Legitimate Interests</Td>
          </Tr>
          <Tr>
            <Td>
              Fraud prevention – to keep our Service and associated systems
              operational and secure.
            </Td>
            <Td>Legitimate Interests</Td>
          </Tr>
          <Tr>
            <Td>
              Legal compliance – to comply with our legal obligations, for
              example our accounting and tax reporting obligations.
            </Td>
            <Td>Compliance with Law</Td>
          </Tr>
          <Tr>
            <Td>
              Marketing – to send you information about our products and
              services (including via email).
            </Td>
            <Td>Consent</Td>
          </Tr>
        </Tbody>
      </Table>
      <Text mt={5}>
        Marketing: We may contact you to provide information we believe will be
        of interest to you. For instance, if you elect to provide your email
        address, we may use that information to send you promotional information
        about our products and services. If we do, we will only send you such
        emails if you consent to us doing so at the time you provide us with
        your Personal Data. You may opt out of receiving emails at any time by
        following the instructions contained in each promotional email we send
        you or by contacting us. If you unsubscribe from our marketing lists,
        you will no longer receive marketing communications but we will continue
        to contact you regarding our Site and Services and to respond to your
        requests.
      </Text>
      <Heading as="h2" size="md" mt={5}>
        HOW WE SHARE AND DISCLOSE PERSONAL DATA
      </Heading>
      <Text mt={3}>
        In certain circumstances, we may share your Personal Data with third
        parties without further notice to you, unless required by the law, as
        set forth below:
      </Text>
      <UnorderedList mt={3}>
        <ListItem>
          Affiliates: Other companies in our corporate group may help us deliver
          our Service to you.
        </ListItem>
        <ListItem>
          Vendors and Service Providers: To assist us in meeting business
          operations needs and to perform certain services and functions, we may
          share Personal Data with service providers, including hosting, cloud
          services and other information technology services providers; email
          communication software providers and email newsletter providers;
          database and sales services; customer relationship management,
          customer engagement, and customer feedback services; payment
          processors; security services; and analytics services. Pursuant to our
          instructions, these parties will access, process or store Personal
          Data in the course of performing their duties to us.
        </ListItem>
        <ListItem>
          Business Transfers: If we are involved in a merger, acquisition,
          financing due diligence, reorganization, bankruptcy, receivership,
          sale of all or a portion of our assets, or transition of service to
          another provider, your Personal Data and other information may be
          shared in the diligence process with counterparties and others
          assisting with the transaction and transferred to a successor or
          affiliate as part of that transaction along with other assets.
        </ListItem>
        <ListItem>
          Legal Requirements: If required to do so by law or in the good faith
          belief that such action is necessary to (i) comply with legal or
          regulatory obligations, including to respond to lawful requests from
          public authorities and to meet national security or law enforcement
          requirements, (ii) protect and defend our rights or property, (iii)
          prevent fraud, (iv) act in urgent circumstances to protect the
          personal safety of users of the Site, or the public, or (v) protect
          against legal liability.
        </ListItem>
        <ListItem>
          Other Users: Your profile and other User-Generated Content (except for
          private messages) may be visible to other users of the Service. For
          example, other users of the Service may have access to your
          information if you choose to make your profile or other personal
          information available to them through the Service.
        </ListItem>
      </UnorderedList>

      <Heading as="h3" size="md" mt={5}>
        CHILDREN
      </Heading>
      <Text mt={3}>
        Our Service is not directed to children who are under the age of 16.
        AetherCode does not knowingly collect Personal Data from children under
        the age of 16. If you have reason to believe that a child under the age
        of 16 has provided Personal Data to AetherCode through the Service,
        please contact us and we will endeavor to delete that information from
        our databases.
      </Text>

      <Heading as="h3" size="md" mt={5}>
        EEA and UK USERS
      </Heading>
      <Text mt={3}>
        Scope: This section applies to users located in the European Economic
        Area (“EEA”) or the United Kingdom (“UK”).
      </Text>
      <Text mt={3}>
        Data Controller: AetherCode, Inc. is the data controller for the
        processing of your Personal Data. You can find our contact information
        in the “Contact Us” section below.
      </Text>
      <Heading as="h3" size="md" mt={5}>
        Legal Bases for Processing
      </Heading>
      <Text mt={3}>
        This Privacy Policy (the paragraph “How We Use Personal Data”) describes
        the legal bases we rely on for the processing of your Personal Data.
        Please <Link href="#">contact us</Link> if you have any questions about
        the specific legal basis we are relying on to process your Personal
        Data.
      </Text>

      <Heading as="h3" size="md" mt={5}>
        Your Rights
      </Heading>
      <Text mt={3}>
        Pursuant to applicable privacy laws in the EEA and the UK, you have the
        following rights in relation to your Personal Data, under certain
        circumstances:
      </Text>
      <UnorderedList mt={3}>
        <ListItem>
          Right of access: If you ask us, we will confirm whether we are
          processing your Personal Data and, if so, provide you with a copy of
          that Personal Data along with certain other details.
        </ListItem>
        <ListItem>
          Right to rectification: If your Personal Data is inaccurate or
          incomplete, you are entitled to ask that we correct or complete it.
        </ListItem>
        <ListItem>
          Right to erasure: You may ask us to delete or remove your Personal
          Data.
        </ListItem>
        <ListItem>
          Right to restrict processing: You may ask us to restrict or &apos;block&apos;
          the processing of your Personal Data in certain circumstances.
        </ListItem>
        <ListItem>
          Right to data portability: You have the right to obtain a copy of your
          Personal Data from us, to the extent that it is processed by automated
          means.
        </ListItem>
        <ListItem>
          Right to object: You may ask us at any time to stop processing your
          Personal Data.
        </ListItem>
        <ListItem>
          Right to withdraw consent: If we rely on your consent to process your
          Personal Data, you have the right to withdraw that consent at any
          time.
        </ListItem>
        <ListItem>
          Right to lodge a complaint with the data protection authority: If you
          have a concern about our privacy practices, you can report it to your
          local data protection authority.
        </ListItem>
      </UnorderedList>

      <Text mt={3}>
        Typically, you will not have to pay a fee to exercise your rights.
        However, we may charge a reasonable fee if your request is clearly
        unfounded, repetitive, or excessive, or we may refuse to comply with
        your request in these circumstances.
      </Text>

      <Text mt={3}>
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your Personal Data. This
        is a security measure to ensure that your Personal Data is not disclosed
        to any person who has no right to receive it. We may also contact you to
        ask you for further information in relation to your request to speed up
        our response.
      </Text>

      <Heading as="h3" size="md" mt={5}>
        INTERNATIONAL TRANSFERS
      </Heading>
      <Text mt={3}>
        AetherCode is based in the United States. Some of our vendors and
        service providers may also be located in the United States or another
        country. As such, any processing of your Personal Data may involve an
        export of your Personal Data outside of your country of residence.
      </Text>
      <Text mt={3}>
        Whenever we transfer your Personal Data out of your country of
        residence, we ensure that a similar degree of protection is afforded to
        it by implementing appropriate safeguards which comply with applicable
        privacy laws. If you would like to receive more information about these
        safeguards, please <Link href="#">Contact Us</Link>.
      </Text>
      <Heading as="h3" size="md" mt={5}>
        LINKS TO OTHER WEBSITES
      </Heading>
      <Text mt={3}>
        The Site and Tapdex Apps may contain advertisements for or links to
        other websites, products, or services not operated or controlled by
        AetherCode, including Social Media Sites (“Third Party Sites”). The
        information that you share with Third-Party Sites will be governed by
        the specific privacy policies and terms of service of the Third-Party
        Sites and not by this Privacy Policy. By providing these links we do not
        imply that we endorse or have reviewed these sites. Please contact the
        Third-Party Sites directly for information on their privacy practices
        and policies.
      </Text>

      <Heading as="h3" size="md" mt={5}>
        SECURITY
      </Heading>
      <Text mt={3}>
        You use the Service at your own risk. We have put in place appropriate
        security measures to prevent your Personal Data from being accidentally
        lost, used or accessed in an unauthorized way, altered or disclosed,
        which comply with industry standards and applicable privacy laws.
        However, no internet or email transmission is ever fully secure or
        error-free. In particular, emails sent to or from us may not be secure.
        Therefore, you should take special care in deciding what information you
        send to us via the Service or email.
      </Text>

      <Heading as="h3" size="md" mt={5}>
        YOUR CHOICES
      </Heading>
      <Text mt={3}>
        Whether or not you provide Personal Data to us is completely up to you,
        but if you choose not to provide information that is needed to use some
        features of our Service, you may be unable to use those features. You
        can also <Link href="#">contact us</Link> to request access to your data
        or to ask us to update, correct, or delete your Personal Data.
      </Text>

      <Heading as="h3" size="md" mt={5}>
        CHANGES TO THE PRIVACY POLICY
      </Heading>
      <Text mt={3}>
        The Service and our business may change from time to time. As a result,
        we may change this Privacy Policy at any time. When we do, we will post
        an updated version on this page, unless another type of notice is
        required by applicable law. By continuing to use our Service or
        providing us with Personal Data after we have posted an updated Privacy
        Policy, you consent to the revised Privacy Policy and practices
        described in it.
      </Text>

      <Heading as="h3" size="md" mt={5}>
        CONTACT US
      </Heading>
      <Text mt={3}>
        If you have any questions about our Privacy Policy or the information
        practices of the Site, please feel free to contact us at
        support@aethercode.com.au.
      </Text>
    </Box>
  );
};

export default PrivacyPolicy;
