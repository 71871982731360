import React from 'react';
import {
  Flex,
  Image,
  Heading,
  Text,
  Button,
  Icon,
  useMediaQuery,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { StyledNavLink } from './Pricing.styles';
import { SLMLogo, Osho } from '../../assets/logos';
import { Check } from '@styled-icons/boxicons-regular/Check';
import { useSubscribe } from '@/hooks/useSubscribe';
import { useQueryAuth } from '@/api/firebase/authentication.hooks';

const Pricing = () => {
  const { handleSubscribe } = useSubscribe();
  const [isDesktop] = useMediaQuery('(min-width: 1000px)');
  const { data } = useQueryAuth();
  return (
    <Flex
      bg="#171717"
      minH="100vh"
      w="100%"
      flexDir="column"
      justifyContent={isDesktop ? 'center' : 'unset'}
    >
      <StyledNavLink to="/" mx="0" ml="35px" mt="20px" zIndex={9999}>
        <Image src={SLMLogo} h="45px" />
      </StyledNavLink>
      {/* <Flex px="35px" justifyContent="center" w="100%">
      <Alert status="info" mt="15px" pb="25px" flexDirection='column'
  alignItems='center'
  justifyContent='center'
  textAlign='center'
  maxW="700px"
  bg="#FFDA2A"
  >
        <AlertIcon mb="10px" color="rgb(43 43 43 / 1)"/>

        <AlertDescription>
          While we are just getting started with sequences and resources being low, we
          are continuously adding more. By subscribing now, you not only ensure
          unlimited access to every new sequence added, but you also support the
          Tapdex vision and future of creating an affordable study tool. Thank you for being an early bird
          subscriber.
        </AlertDescription>
      </Alert>
      </Flex> */}
      <Heading
        pt="30px"
        // pl="30px"
        as="h1"
        fontSize="28px"
        alignSelf="center"
        // marginBottom="4"
        textAlign="center"
        fontFamily="Britanica-Black"
        // letterSpacing="1px"
        fontWeight="500"
        //maxW="230px"
        // bgGradient="linear-gradient(157.4deg, #1085c7 7.96%, #fff 60%)"
        bg="white"
        backgroundClip="text"
        color="transparent"
        lineHeight="35px"
        px="45px"
        // mb="7px"
      >
        Unlock unlimited access and contribute to the future of grappling.
      </Heading>

      <Flex
        alignSelf="center" //{isDesktop ? 'center' : 'unset'}
        justifyContent="center"
        alignItems="center"
        w="100%"
        px="45px"
        mt="50px"
        maxW="400px"
      >
        <Flex
          w="100%"
          // h="500px"

          borderRadius="25px"
          bgGradient="linear(to-r, #f6d4a1, #ed8445)"
          backgroundImage="linear-gradient(117deg, #f6d4a1, #ed8445)"
          p=".125rem"
          alignSelf="center"
        >
          <Flex
            borderRadius="25px"
            flexDir="column"
            w="100%"
            bg="rgb(31 31 31)"
            pb="30px"
            // border="1px solid #FFDA2A"
            boxShadow="
        0px 0px 0px 0px rgba(0, 0, 0, 0.1), 
        0px 7px 15px 0px rgba(0, 0, 0, 0.1), 
        0px 28px 28px 0px rgba(0, 0, 0, 0.09), 
        0px 63px 38px 0px rgba(0, 0, 0, 0.05), 
        0px 112px 45px 0px rgba(0, 0, 0, 0.01), 
        0px 175px 49px 0px transparent
      "
            backgroundImage="radial-gradient(58.99% 10.42% at 50% 100.46%, rgba(251, 188, 5, 0.07) 0%, transparent 100%), radial-gradient(135.76% 66.69% at 92.19% -3.15%, rgba(251, 5, 153, 0.1) 0%, transparent 100%), radial-gradient(127.39% 38.15% at 22.81% -2.29%, rgba(239, 145, 84, 0.4) 0%, transparent 100%)"
          >
            <Heading
              pt="30px"
              pl="30px"
              as="h1"
              fontSize="28px"
              // marginBottom="4"
              textAlign="left"
              fontFamily="Britanica-Black"
              // letterSpacing="1px"
              fontWeight="500"
              //maxW="230px"
              // bgGradient="linear-gradient(157.4deg, #1085c7 7.96%, #fff 60%)"
              bg="white"
              backgroundClip="text"
              color="transparent"
              lineHeight="35px"
              mb="7px"
            >
              Unlimited
            </Heading>
            <Text
              pl="30px"
              color="#AEAEAE"
              textAlign="left"
              // fontWeight="bold"
              fontSize="14px"
              maxW="250px"
              w="100%"
              alignSelf="left"
              mb="25px"
            >
              For BJJ practitioners, competitors, and coaches
            </Text>
            <Flex alignItems="center" pl="30px">
              <Text
                pr="5px"
                color="rgb(240 158 99)"
                textAlign="left"
                // fontWeight="bold"
                fontSize="16px"
                maxW="250px"
                // w="100%"
                alignSelf="left"
                mt="14px"
              >
                US$
              </Text>
              <Text
                pr="6px"
                color="rgb(240 158 99)"
                textAlign="left"
                fontWeight="500"
                fontSize="50px"
                maxW="250px"
                // w="100%"
                alignSelf="left"
                mb="0px"
              >
                7.95
              </Text>
              <Text
                // pl="30px"
                color="rgb(240 158 99)"
                textAlign="left"
                // fontWeight="bold"
                fontSize="16px"
                maxW="250px"
                // w="100%"
                alignSelf="left"
                mt="14px"
              >
                /mo
              </Text>
            </Flex>
            <Text
              pl="30px"
              color="#AEAEAE"
              textAlign="left"
              // fontWeight="bold"
              fontSize="14px"
              maxW="250px"
              // w="100%"
              alignSelf="left"
              mb="25px"
            >
              Billed monthly
            </Text>
            <Button
              borderRadius="25px"
              bgGradient="linear(to-r, #f6d4a1, #ed8445)"
              backgroundImage="linear-gradient(117deg, #f6d4a1, #ed8445)"
              fontSize="14px"
              mx="30px"
              _hover={{
                bgGradient: 'linear(to-r, #f0c687, #d96a28)', // Darker gradient on hover
                transform: 'scale(1.05)', // Slightly enlarge the button on hover
                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)', // Add a subtle shadow on hover
              }}
              transition="all 0.3s ease" // Smooth transition for the hover
              onClick={() => handleSubscribe(data ?? undefined)}
            >
              Select
            </Button>
            <Text
              mt="30px"
              pl="30px"
              color="white"
              textAlign="left"
              fontWeight="bold"
              fontSize="14px"
              maxW="250px"
              // w="100%"
              alignSelf="left"
              mb="15px"
            >
              Plan includes:
            </Text>
            <Flex alignItems="center" ml="22px">
              <Icon as={Check} boxSize="6" color="#AEAEAE" mr="5px" />
              <Text
                color="#AEAEAE"
                textAlign="center"
                //fontWeight="bold"
                fontSize="14px"
              >
                Unlimited sequences
              </Text>
            </Flex>
            <Flex alignItems="center" ml="22px">
              <Icon as={Check} boxSize="6" color="#AEAEAE" mr="5px" />
              <Text
                color="#AEAEAE"
                textAlign="center"
                //fontWeight="bold"
                fontSize="14px"
              >
                Unlimited resources
              </Text>
            </Flex>
            <Flex alignItems="center" ml="22px">
              <Icon as={Check} boxSize="6" color="#AEAEAE" mr="5px" />
              <Text
                color="#AEAEAE"
                textAlign="center"
                //fontWeight="bold"
                fontSize="14px"
              >
                Contribution priority
              </Text>
            </Flex>
            <Flex alignItems="center" ml="22px">
              <Icon as={Check} boxSize="6" color="#AEAEAE" mr="5px" />
              <Text
                color="#AEAEAE"
                textAlign="center"
                // fontWeight="bold"
                fontSize="14px"
              >
                Email directly with dev
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Heading
        pt="30px"
        // pl="30px"
        as="h1"
        fontSize="23px"
        alignSelf="center"
        // marginBottom="4"
        textAlign="center"
        fontFamily="Britanica-Black"
        // letterSpacing="1px"
        fontWeight="500"
        //maxW="230px"
        // bgGradient="linear-gradient(157.4deg, #1085c7 7.96%, #fff 60%)"
        bg="white"
        backgroundClip="text"
        color="transparent"
        lineHeight="35px"
        px="35px"
        mb="-45px"
      >
        {'"For the grapplers, of the grapplers"'}
      </Heading>
      <Image src={Osho} h="245px" mt="30px"></Image>
    </Flex>
  );
};

export default Pricing;
