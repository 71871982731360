import { useAppDispatch } from '@/hooks/useRedux';
import { TSignUpForm } from '../SignUp.types';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import { useQueryAuth } from '@/api/firebase/authentication.hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAuthError, setIsAuthLoading } from '@/redux/Global.slice';
import { useSubscribe } from '@/hooks/useSubscribe';
import { logErrorEvent, logSignUpEvent } from '@/utils/analytics';

export const useSignUpFormHOC = () => {
  const dispatch = useAppDispatch();
  const auth = getAuth();
  const { refetch } = useQueryAuth();
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSubscribe } = useSubscribe();

  const onSubmit = async (form: TSignUpForm) => {
    if (!form) return;

    dispatch(setIsAuthLoading(true));

    createUserWithEmailAndPassword(auth, form.email, form.password)
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        // Track login event
        logSignUpEvent(user);
        // Set the display name
        updateProfile(user, {
          displayName: form.fullName,
        })
          .then(() => {
            if (!location.state?.fromSubscribe) {
              navigate('/');
              dispatch(setIsAuthLoading(false));
            } else {
              refetch()
                .then((user) => {
                  if (user.data) {
                    handleSubscribe(user.data);
                  }
                })
                .catch((error) => {
                  logErrorEvent(error, 'Error fetching user after sign up');
                  dispatch(setAuthError('Error fetching user after sign up'));
                  dispatch(setIsAuthLoading(false));
                });
            }

            dispatch(setAuthError(''));
          })
          .catch((error) => {
            logErrorEvent(error, 'Error updating profile');
            dispatch(setAuthError('Failed to set display name'));
          });
      })
      .catch((error) => {
        const errorMessage = error.message;
        logErrorEvent(error, 'Error signup failed');
        dispatch(setAuthError(errorMessage));
      })
      .finally(() => dispatch(setIsAuthLoading(false)));
  };

  const signUpWithGoogle = () => {
    dispatch(setIsAuthLoading(true));
    signInWithPopup(auth, provider)
      .then((result) => {
        if (!result) {
          logErrorEvent(result, 'Error no result after google signup');
          return;
        }

        if (!location.state?.fromSubscribe) {
          navigate('/');
          dispatch(setIsAuthLoading(false));
        } else {
          refetch()
            .then((user) => {
              if (user.data) {
                handleSubscribe(user.data);
              }
            })
            .catch((error) => {
              logErrorEvent(
                error,
                'Error fetching user after sign up via google'
              );
              dispatch(
                setAuthError('Error fetching user after sign up google')
              );
              dispatch(setIsAuthLoading(false));
            });
        }
        dispatch(setAuthError(''));
      })
      .catch((error) => {
        const errorMessage = error.message;
        logErrorEvent(error, 'Error signup with google');
        dispatch(setAuthError(errorMessage));
      });
  };

  return {
    onSubmit,
    signUpWithGoogle,
  };
};
