/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { FlexPageWrapper } from './HomePage.styles';
import Feed from './components/Feed/Feed';
// import Hero from './components/Hero';
import herovid from '../../assets/videos/herovid.mp4';
import { useHomePage } from './services/HomePage.hooks';
import {
  Flex,
  useTheme,
  Text,
  useMediaQuery,
  Heading,
  Button,
  Box,
  Image,
  Progress,
  Spacer,
  WrapItem,
  Wrap,
  Grid,
} from '@chakra-ui/react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { DropdownIndicator } from '../homepage/DropDownIndicator';
import { FormattedMessage } from 'react-intl';
import { searchHomeBarStyles } from './HomePage.styles';
import { SearchBarGeoCoder } from '@/common/components/SearchBarTechniques/SearchBarTechniques';
import { useAppSelector } from '@/hooks/useRedux';
import { useQueryAuth } from '@/api/firebase/authentication.hooks';
import { useNavigate } from 'react-router-dom';
import { useSubscribe } from '@/hooks/useSubscribe';
import { formatSecondsToTime } from './services/HomePage.utils';
import CountUp from 'react-countup';
import LoadMoreButton from '@/common/components/LoadMore/LoadMore';
import { EPagination } from '@/api/dbModels.types';
import { Pagination } from '@/common/components/Pagination/Pagination';
import { Osho } from '../../assets/logos';
import UpgradeModal from '@/common/components/UpgradeModal/UpgradeModal';

const HomePage: React.FC = () => {
  const [isDesktop] = useMediaQuery('(min-width: 1000px)');
  const [isMobile] = useMediaQuery('(max-width: 400px)');
  const theme = useTheme();
  const { data } = useQueryAuth();
  const { isSubscribed } = useAppSelector((state) => state.globalSlice);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { handleSubscribe } = useSubscribe();

  const {
    resources,
    totals,
    isYTLoading,
    techniqueOptions,
    selectedTechniqueLabels,
    shouldDisableNextFetch,
    shouldDisablePreviousFetch,
    isModalOpen,
    setIsModalOpen,
    getSequences,
    jumpToTimestamp,
    onSequenceSelect,
  } = useHomePage();

  const renderResources = resources.map((rcs, i) => (
    <Flex key={`${rcs.id}${i}`} mt="25px" flexDir="column" w="100%" gap="0px">
      <div style={{ zIndex: 99 }} id={rcs.id}></div>
      {isYTLoading ? (
        <Progress size="xs" isIndeterminate colorScheme="brand" bg="black" />
      ) : (
        <Spacer h="5px" />
      )}

      {rcs.display.map((dsply) => (
        <Flex
          key={dsply.id}
          // flexDir="row"
          // mt="20px"
          // // paddingX="15px"
          // w="100%"
          // alignItems="center"
          alignItems="center"
          justifyContent="space-between"
          py="4"
          px="6"
          bg="#262626" //"gray.800"
          //borderRadius="md"
          borderBottomRadius="md"
          // boxShadow="0 4px 12px rgba(0, 0, 0, 0.15), 0 -4px 0 rgba(0, 0, 0, 0.15) inset" // Custom shadow: no top shadow
          boxShadow="0 4px 12px rgba(0, 0, 0, 0.15)"
          transition="all 0.3s ease"
          minH="60px"
          _hover={{
            bg: '#2b2b2b', //'#404040',
          }}
        >
          <Box
            flex="1"
            mr="4"
            borderLeft="4px solid #FFDA2A"
            pl="4"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              // color="white"
              // fontFamily="Roboto"
              // mr="15px"
              color="white"
              fontSize="lg"
              fontWeight="medium"
              letterSpacing="wide"
              textTransform="uppercase"
              // noOfLines={1}
            >
              {dsply.name.charAt(0).toUpperCase() +
                dsply.name.slice(1).replace(/-/g, ' ')}
              :
            </Text>
          </Box>
          {dsply.sequences.map(({ _id, timeStamp }) => {
            return (
              <Button
                key={_id}
                bg="#FFDA2A" // Main color
                color="black"
                fontSize="lg"
                fontWeight="bold"
                textTransform="uppercase"
                border="3px solid transparent"
                letterSpacing="wide"
                borderRadius="full"
                boxShadow="0 4px 12px rgba(0, 0, 0, 0.15)"
                px="8"
                py="5"
                transition="all 0.3s ease"
                _hover={{
                  bg: 'black',
                  color: '#FFDA2A',
                  transform: 'scale(1.05)',
                  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  border: '3px solid #FFDA2A',
                  _before: {
                    opacity: 1,
                    transform: 'scale(1.05)',
                  },
                }}
                _active={{
                  bg: 'black',
                  color: '#FFDA2A',
                  transform: 'scale(1)',
                  boxShadow: '0 3px 9px rgba(0, 0, 0, 0.2)',
                  border: '3px solid transparent',
                  paddingY: '5',
                }}
                // _focus={{
                //   // outline: 'none',
                //   bg: 'black',
                //   color: '#FFDA2A',
                //   // boxShadow: '0 0 0 4px rgba(255, 218, 42, 0.6)',
                //   border: '0px solid #FFDA2A',
                //   _before: {
                //     opacity: 1,
                //     transform: 'scale(1.05)',
                //   },
                // }}
                _before={{
                  content: '""',
                  position: 'absolute',
                  top: '-3px',
                  left: '-3px',
                  right: '-3px',
                  bottom: '-3px',
                  borderRadius: 'inherit',
                  border: '0px solid #FFDA2A',
                  zIndex: -1,
                  transition: 'all 0.3s ease',
                  opacity: 0,
                }}
                // bg="brand.500" //"rgb(43 43 43 / 1)"
                // textColor="black"
                // _hover={{
                //   bg: 'brand.400',
                // }}
                // style={{
                //   // background: 'rgb(43 43 43 / 1)',
                //   // color: 'black', // 'white',
                //   fontWeight: '400',
                //   fontFamily: 'Roboto',
                //   boxShadow: '0 8px 15px rgba(0, 0, 0, 0.3)', // More pronounced shadow
                //   padding: '10px 20px', // Adding padding for better visual appeal
                //   borderRadius: '25px', // Adding border radius for rounded corners
                //   transition: 'box-shadow 0.3s ease-in-out', // Adding transition for smooth shadow effect
                // }}
                onClick={() => jumpToTimestamp(rcs.id, timeStamp)}
                // onMouseEnter={(e) =>
                //   (e.currentTarget.style.boxShadow =
                //     '0 12px 20px rgba(0, 0, 0, 0.4)')
                // } // Enhanced shadow on hover
                // onMouseLeave={(e) =>
                //   (e.currentTarget.style.boxShadow =
                //     '0 8px 15px rgba(0, 0, 0, 0.3)')
                // }
              >
                {formatSecondsToTime(timeStamp)}
              </Button>
            );
          })}
        </Flex>
      ))}
    </Flex>
  ));

  return (
    <FlexPageWrapper position="relative">
      <UpgradeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        handleSubscribe={() => {
          handleSubscribe(data ?? undefined);
        }}
      />
      {isDesktop && (
        <Flex
          h="100vh"
          w="100vw"
          justify="center"
          align="center"
          position="absolute"
          overflow="hidden"
        >
          <video
            src={herovid}
            autoPlay
            loop
            muted
            style={{
              position: 'absolute',
              top: '40%',
              left: '80%',
              width: '100vw',
              height: '100vh',
              objectFit: 'cover',
              transform: 'translate(-50%, -50%)',
              zIndex: '-999px',
            }}
          />
          <Flex
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            backgroundImage="linear-gradient(355.45deg, #171717 19.99%, hsla(0, 0%, 9%, 0) 52.67%), linear-gradient(89.85deg, #171717 32.84%, hsla(0, 0%, 9%, 0) 60.63%)"
            zIndex="1"
          />
        </Flex>
      )}
      <Flex
        direction="column"
        position="relative"
        zIndex="1"
        w="100%"
        h="100%"
        bg="transparent"
      >
        {/** HERO */}
        <Flex
          key="0"
          pt={isDesktop ? '8.5rem' : 'unset'}
          pl={isDesktop ? '8.5rem' : 'unset'}
          flexDir="column"
          w="100%"
          aria-label="HeroGroupContainer"
          bg="transparent"
          justifyContent="center"
          alignItems={isDesktop ? 'left' : 'center'}
        >
          <Heading
            pt="35px"
            as="h1"
            fontSize={isDesktop ? '3.125rem' : '30px'}
            marginBottom={isDesktop ? '8' : '4'}
            textAlign={isDesktop ? 'left' : 'center'}
            fontFamily="Britanica-Black"
            letterSpacing="1px"
            fontWeight="600"
            maxW={isDesktop ? '500px' : '230px'}
            backgroundClip="text"
            color="white"
            mr="30px"
            mb={isMobile ? '30px' : '30px'}
            ml={isDesktop ? '0px' : '30px'}
            lineHeight={isDesktop ? '60px' : '35px'}
          >
            {isDesktop ? (
              // <Box>
              //   The grappling <br /> tape study library
              // </Box>
              <Box>
                The ultimate grappling <br /> tape study library
              </Box>
            ) : (
              <Box>The ultimate grappling tape study library</Box>
            )}
          </Heading>
          <Flex
            // bg="red"
            justify="center"
            w={isDesktop ? 'unset' : '90vw'}
            maxWidth={isDesktop ? '570px' : 'unset'}
            bottom={isMobile ? '-25px' : '-9'}
            aria-label="InputContainer"
            top="130"
            flexDir="column"
            alignItems={isDesktop ? 'left' : 'center'}
          >
            <SearchBarGeoCoder
              selectedOptions={selectedTechniqueLabels}
              onSelect={onSequenceSelect}
              aggregates={techniqueOptions}
              styles={searchHomeBarStyles(theme, isMobile, isDesktop)}
              placeholder={
                <Text fontSize={isMobile ? '16px' : '16px'} pl="0">
                  <FormattedMessage id="searchbar.placeholder" />
                </Text>
              }
              icon={DropdownIndicator}
            />
            {(() => {
              // User login in but not subscribed
              if (!isSubscribed && data?.uid) {
                return (
                  <Flex
                    justifyContent={isDesktop ? 'left' : 'center'}
                    w="100%"
                    mt={isDesktop ? '35px' : '35px'}
                    mb="15px"
                    ml="3px"
                  >
                    <Button
                      borderRadius="full"
                      bg="#FFDA2A"
                      fontSize="14px"
                      minW="160px"
                      // minH="45px"
                      mr="7px"
                      isLoading={loading}
                      onClick={() => {
                        setLoading(true);
                        handleSubscribe(data).then(() => setLoading(false));
                      }}
                    >
                      Get Unlimited Access
                    </Button>
                  </Flex>
                );
              }

              // User is loged in and subscribed
              if (isSubscribed && data?.uid) {
                return <></>;
              }

              return (
                <Flex
                  justifyContent={isDesktop ? 'left' : 'center'}
                  w="100%"
                  mt={selectedTechniqueLabels.length ? '25px' : '30px'}
                  mb="15px"
                  ml={isDesktop ? '3px' : 'unset'}
                >
                  <Button
                    borderRadius="full"
                    bg="#FFDA2A"
                    fontSize="14px"
                    minW="160px"
                    // minH="45px"
                    mr="7px"
                    _hover={{
                      bg: '#CEB021', // Slightly darker yellow on hover
                      // transform: 'scale(1.05)', // Slightly enlarge the button on hover
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Add a subtle shadow on hover
                    }}
                    transition="all 0.3s ease"
                    onClick={() => navigate('/signup')}
                  >
                    Start Free Now
                  </Button>
                  <Button
                    borderRadius="full"
                    variant="outline"
                    color="white"
                    fontSize="14px"
                    minW="160px"
                    ml="7px"
                    // minH="45px"
                    // py="10px"
                    _hover={{
                      color: '#FFDA2A', // Change text color to a brighter yellow on hover
                      borderColor: '#FFDA2A', // Change the border to a brighter yellow on hover
                      // transform: "scale(1.05)", // Slightly enlarge the button on hover
                    }}
                    transition="all 0.3s ease"
                    isLoading={loading}
                    onClick={() => navigate('/pricing')}
                  >
                    Pricing
                  </Button>
                </Flex>
              );
            })()}
          </Flex>
        </Flex>

        {/* <Hero onFire={(r) => setT(r)} onFFire={(r) => setF(r)}/> */}

        {/** FEED */}
        {/* <Feed t={t} f={f} /> */}
        <Flex
          flexDir="column"
          px="0"
          pt="0"
          mb="0"
          // bg="#171717"
          mt={isDesktop ? '30px' : 'unset'}
          w="100svw"
          alignItems="center"
          // bg="red"
        >
          {(() => {
            if (!resources.length) {
              return (
                <Flex
                  flexDir={isDesktop ? 'row' : 'column'}
                  justifyContent="center"
                  alignItems="center"
                  gap="20px"
                  mt={isMobile ? '50px' : '30px'}
                >
                  <Text color="white">Sequences</Text>
                  <Flex
                    borderRadius="full"
                    borderWidth="8px"
                    borderColor="#FFDA2A"
                    color="white"
                    p="5px"
                    h="85px"
                    w="85px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CountUp
                      start={0}
                      end={totals.sequences}
                      duration={2.75}
                      style={{ fontSize: '16px !important' }}
                    ></CountUp>
                    +
                  </Flex>
                  <Text color="white">Techniques</Text>
                  <Flex
                    borderRadius="full"
                    borderWidth="8px"
                    borderColor="#FFDA2A"
                    color="white"
                    p="5px"
                    h="85px"
                    w="85px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CountUp
                      start={0}
                      end={totals.techniques}
                      duration={2.75}
                      style={{ fontSize: '16px !important' }}
                    ></CountUp>
                    +
                  </Flex>
                  <Text color="white">Rolls & Matches</Text>
                  <Flex
                    borderRadius="full"
                    borderWidth="8px"
                    borderColor="#FFDA2A"
                    color="white"
                    p="5px"
                    h="85px"
                    w="85px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CountUp
                      start={0}
                      end={totals.rollsAndMatches}
                      duration={2.75}
                    ></CountUp>
                    +
                  </Flex>
                </Flex>
              );
            }

            if (loading) {
              return <Progress size="xs" isIndeterminate />;
            }

            return <></>;
          })()}

          {(() => {
            if (isDesktop) {
              return (
                <Grid
                  // bg="red"
                  px="30px"
                  w="100vw"
                  templateColumns="repeat(auto-fill, minmax(500px, 1fr))"
                  gap={6}
                >
                  {renderResources}
                </Grid>
              );
            }

            return renderResources;
          })()}

          {(() => {
            if (renderResources.length && isMobile) {
              return !shouldDisableNextFetch ? (
                <LoadMoreButton
                  isLoading={isYTLoading}
                  isLastFetch={shouldDisableNextFetch}
                  mt="30px"
                  maxW="150px"
                  onLoadMore={() =>
                    getSequences(selectedTechniqueLabels, EPagination.Next)
                  }
                />
              ) : (
                <></>
              );
            } else if (renderResources.length) {
              return (
                <Pagination
                  nextDisabled={shouldDisableNextFetch}
                  previousDisabled={shouldDisablePreviousFetch}
                  allDisabled={isYTLoading}
                  onPageChange={(direction: EPagination) =>
                    getSequences(selectedTechniqueLabels, direction)
                  }
                />
              );
            }
          })()}

          <Heading
            pt="30px"
            mt="20px"
            // pl="30px"
            as="h1"
            fontSize="23px"
            alignSelf="center"
            // marginBottom="4"
            textAlign="center"
            fontFamily="Britanica-Black"
            // letterSpacing="1px"
            fontWeight="500"
            //maxW="230px"
            // bgGradient="linear-gradient(157.4deg, #1085c7 7.96%, #fff 60%)"
            bg="white"
            backgroundClip="text"
            color="transparent"
            lineHeight="35px"
            px="35px"
            mb="-45px"
          >
            {'"For the grapplers, of the grapplers"'}
          </Heading>
          <Image src={Osho} h="245px" mt="30px" bg="transparent"></Image>
        </Flex>
      </Flex>
    </FlexPageWrapper>
  );
};

export default HomePage;
