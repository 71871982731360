import React from 'react';
import { Box, Text, Heading, UnorderedList, ListItem } from '@chakra-ui/react';

const TermsAndConditions = () => {
  return (
    <Box p={10} color="white" w="100svw">
      <Heading as="h1" size="lg" w="100svw">
        Terms of Service
      </Heading>
      <Text mt={4}>Date of Last Revision: August 1, 2024</Text>

      <Heading as="h2" size="md" mt={8}>
        1. GENERAL INFORMATION
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          TAPDEX PRODUCTS - These Terms & Conditions contain the terms and
          conditions on which we supply content, products, or services listed on
          www.tapdex.io.
        </ListItem>
        <ListItem>
          U.S. USERS – ARBITRATION NOTICE AND CLASS ACTION WAIVER - PLEASE NOTE
          THAT THESE TERMS CONTAIN AN ARBITRATION AGREEMENT. EXCEPT FOR CERTAIN
          TYPES OF DISPUTES, YOU AND AETHERCODE AGREE THAT DISPUTES WILL BE
          RESOLVED BY MANDATORY BINDING ARBITRATION, AND YOU WAIVE ANY RIGHT TO
          PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
        </ListItem>
        <ListItem>
          SUBSCRIPTION RENEWAL TERMS - If you purchase a subscription, you agree
          that your subscription will automatically renew for additional periods
          unless you opt out of the auto-renewal.
        </ListItem>
        <ListItem>
          CHANGES TO TERMS - AetherCode reserves the right to change or update
          these Terms at any time. If a revision is material, we will provide at
          least 30 days notice.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        2. LICENSE
      </Heading>
      <UnorderedList mt={4} spacing="4">
        <ListItem>
          Subject to your ongoing compliance with these Terms, and payment of
          all applicable fees, AetherCode grants you a limited, non-exclusive,
          non-transferable, revocable license to stream, download and make
          personal non-commercial use of the Products.
        </ListItem>
        <ListItem>
          The Products contain or embody copyrighted material, proprietary
          material or other intellectual property of AetherCode or its
          licensors. All right, title and ownership in the Products remain with
          AetherCode or its licensors, as applicable. The rights to download and
          use the Products are licensed to you and are not being sold to you,
          and you have no rights in them other than to use them in accordance
          with this License Agreement and our other Terms.
        </ListItem>
        <ListItem>
          A limited amount of Materials (as defined below) may be marked and
          authorized by AetherCode for users to share in their personal social
          channels (Facebook, Twitter, etc.), as part of your blog or other
          online commentary, analysis or review (“User Commentary”) in
          accordance with the functionality of the Products (such Materials, the
          “Distribution Content”). AetherCode grants you a limited right to
          download, reproduce and distribute Distribution Content over the
          internet solely as part of your User Commentary. You may modify such
          Distribution Content solely as required to technically enable the
          display and distribution of such Distribution Content (e.g., a change
          in video format or file size) provided such modification does not
          materially alter the substance or quality of such Distribution
          Content. Your display and distribution of Distribution Content may
          also be subject to other terms and conditions that are set forth in
          the description of such content in the Products, such as time-limited
          restrictions on display and distribution of Distribution Content. You
          agree not to publish the Distribution Content with other content that
          is false, inaccurate, or misleading or that is, or that encourages
          activity or conduct that is, unlawful, harmful, threatening, abusive,
          harassing, tortious, defamatory, vulgar, obscene, pornographic,
          libelous, invasive of another’s privacy, hateful, or racially,
          ethnically or otherwise objectionable. Distribution Content may
          contain trackers that enable us to collect information with respect to
          the distribution and consumption of such content. AetherCode reserves
          the right to discontinue providing any Distribution Content at any
          time, or to direct you to cease displaying, or otherwise using, any
          Distribution Content for any or no reason, without liability to you or
          any third party.
        </ListItem>
        <ListItem>
          You may link to our home page, provided you do so in a way that is
          fair and legal and does not damage our reputation or take advantage of
          it, but you must not establish a link in such a way as to suggest any
          form of association, approval or endorsement on our part where none
          exists. You must not establish a link from any website that is not
          owned by you. The Products must not be framed on any other website,
          nor may you create a link to any part of the Products unless you have
          written permission to do so from AetherCode. We reserve the right to
          withdraw such permission immediately upon written notice to you. The
          website from which you are linking must comply in all respects with
          the content standards set out in our acceptable use policy. If you
          wish to make any use of Materials (as defined below) on or in the
          Products other than that set out above, please address your request to
          support@aethercode.com.au.
        </ListItem>
        <ListItem>
          You agree that you will not and you will not assist or permit any
          third party to: (a) copy, store, reproduce, transmit, modify, alter,
          reverse-engineer, emulate, de-compile, or disassemble the Products in
          any way, or create derivative works of the Products; (b) use the
          Products or any part of them to create any tool or software product
          that can be used to create software applications of any nature
          whatsoever; (c) rent, lease, loan, make available to the public, sell
          or distribute the Products in whole or in part; (d) tamper with the
          Products or circumvent any technology used by AetherCode or its
          licensors to protect any content accessible through the Products; (e)
          circumvent any territorial restrictions applied to the Products; or
          (f) use the Products for any commercial purpose without the express
          written consent of AetherCode. Except as expressly set forth in these
          Terms, you may not otherwise download, display, copy, reproduce,
          distribute, modify, perform, transfer, create derivative works from,
          sell or otherwise exploit any content, code, data or Materials in the
          Products.
        </ListItem>
        <ListItem>
          If you purchase a Subscription, such Subscription may include the
          right to create and reproduce an unlimited number of hard copy
          reproductions of certain Materials (as defined below) contained in the
          Products for your personal, in-house business, or intra-institutional
          use, subject to the property rights notice and credit requirements set
          forth below. Any included AetherCode or third party copyright notices
          must appear in legible form on or adjacent to each reproduction. You
          may distribute hard copy reproductions on paper in accordance with
          this section, as long as the reproductions are distributed without
          charge, in a non-commercial manner and for non-commercial purposes. We
          ask that you obtain a supplemental license in writing from AetherCode
          before you reproduce any reproductions in a publication that is for
          sale or resale or that contains paid advertisements.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        3. USER CONTRIBUTIONS AND REWARDS
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          Users can contribute sequences to the Tapdex database by logging
          sequences through the platform. These contributions are subject to
          review and approval by AetherCode.
        </ListItem>
        <ListItem>
          Users may earn rewards, such as a free month of subscription, for
          every 25 sequences added and approved. AetherCode reserves the right
          to modify or discontinue the reward structure at any time without
          prior notice.
        </ListItem>
        <ListItem>
          The criteria for sequence approval include, but are not limited to,
          the accuracy of the technique description, relevance to BJJ, and
          compliance with community standards.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        4. SUBSCRIPTION AND PAYMENT TERMS
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          Subscription fees are charged on a monthly basis and may be increased
          or decreased at AetherCode&apos;s discretion. Users will be notified of any
          changes to subscription fees in advance.
        </ListItem>
        <ListItem>
          Subscriptions renew automatically unless the user opts out of
          auto-renewal. Users can manage their subscription settings through
          their account.
        </ListItem>
        <ListItem>
          Refunds for subscriptions will be handled on a case-by-case basis and
          are not guaranteed.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        5. ACCOUNTS AND SECURITY
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          You are responsible for maintaining the confidentiality of your
          account and password. You agree to accept responsibility for all
          activities that occur under your account or password.
        </ListItem>
        <ListItem>
          You must notify us immediately upon becoming aware of any breach of
          security or unauthorized use of your account.
        </ListItem>
        <ListItem>
          You must not use as a username the name of another person or entity
          that is not lawfully available for use, a name or trademark that is
          subject to any rights of another person or entity other than you,
          without appropriate authorization.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        6. TERMINATION
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          We may terminate or suspend your account and access to the Products
          immediately, without prior notice or liability, for conduct that we,
          in our sole discretion, believe to be harmful to other users of the
          Products, us or third parties, or for violating any terms in this
          agreement.
        </ListItem>
        <ListItem>
          If you wish to terminate your account, you may simply discontinue
          using the Products. Your content may persist in our backups for some
          time.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        7. GOVERNING LAW
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          These Terms are governed in accordance with the laws of Australia,
          without regard to its conflict of law principles.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        8. CONTACT INFORMATION
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          For any questions about these Terms, please contact us at
          support@aethercode.com.au.
        </ListItem>
      </UnorderedList>
    </Box>
  );
};

export default TermsAndConditions;
