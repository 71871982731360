import React from 'react';
import { FlexPageWrapper } from '../signin/SignIn.styles';

import {
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Box,
  useMediaQuery,
} from '@chakra-ui/react';

const Faq: React.FC = () => {
  const [isDesktop] = useMediaQuery('(min-width: 1000px)');
  return (
    <FlexPageWrapper
      pb="50px"
      px="30px"
      alignItems="left"
      justifyContent="left"
      maxW={isDesktop ? '50vw' : 'unset'}
      mx={isDesktop ? 'auto' : 'unset'}
    >
      <Heading
        pt="35px"
        as="h1"
        fontSize="30px"
        marginBottom="4"
        // textAlign="center"
        fontFamily="Britanica-Black"
        // letterSpacing="1px"
        fontWeight="500"
        //maxW="230px"
        // bgGradient="linear-gradient(157.4deg, #1085c7 7.96%, #fff 60%)"
        bg="white"
        backgroundClip="text"
        color="transparent"
        lineHeight="35px"
        mb="20px"
      >
        FAQ
      </Heading>
      <Text color="white" textAlign="left" mb="40px">
        Have more questions? Reach out at info@tapdex.io
      </Text>
      <Accordion defaultIndex={[0]} allowMultiple zIndex={999}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" color="white">
                1. What rewards do I get for contributing to the database?
              </Box>
              <AccordionIcon color="white" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} color="white">
            Yes, we do offer rewards! Although we just launched this product and
            are still finalising our reward plans, any significant contribution
            to our database will be met with free or discounted subscriptions.
            Stay tuned for more details!
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" color="white">
                2. Are there resources from platforms other than YouTube?
              </Box>
              <AccordionIcon color="white" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} color="white">
            Not yet, but we are already working on extending our resources to
            include content from FloGrappling and UFC. This feature should
            launch soon, so keep an eye out for updates!
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" color="white">
                3. How do I access my subscription benefits?
              </Box>
              <AccordionIcon color="white" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} color="white">
            {
              "Once you subscribe, you'll have instant access to all recourses. Simply log in to your account, and your subscription benefits will be available to you. If you experience any issues, feel free to reach out to our support team at info@tapdex.io."
            }
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </FlexPageWrapper>
  );
};

export default Faq;
