import React, { FC, useRef, useState } from 'react';
import {
  Box,
  Collapse,
  Image,
  Spacer,
  Show,
  Hide,
  IconButton,
  Icon,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  Button,
  Text,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import { HeaderWrapper, NavText, StyledNavLink } from './styles/Header.styles';
import { SLMLogo } from '../../../assets/logos';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { SearchBarGeoCoder } from '../../components/SearchBarTechniques/SearchBarTechniques';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { Menu } from '@styled-icons/feather/Menu';
import { useQueryAuth } from '@/api/firebase/authentication.hooks';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import {
  setIsSubscribed,
  setSubscriptionId,
  setUuid,
} from '@/redux/Global.slice';
import { useSubscribe } from '@/hooks/useSubscribe';
// import { useBulkWrite } from '@/hooks/useBulkWrite';
const Header: FC = () => {
  const { pathname } = useLocation();
  const auth = getAuth();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const { isSubscribed } = useAppSelector((state) => state.globalSlice);

  const { data } = useQueryAuth();

  const [isDesktop] = useMediaQuery('(min-width: 1000px)');
  const { handleSubscribe } = useSubscribe();
  const [loading, setLoading] = useState(false);

  // useBulkWrite();
  // useSetData()

  return (
    <>
      <HeaderWrapper>
        <Show breakpoint="(min-width: 400px)">
          <StyledNavLink to="/home" mx="0" ml="10px">
            <Image src={SLMLogo} h="25px" />
          </StyledNavLink>
          <StyledNavLink to="/faq" ml="8">
            <NavText fontFamily="Britanica-Black">FAQ</NavText>
          </StyledNavLink>
          {(() => {
            if (!data) {
              return (
                <StyledNavLink to="/signin">
                  <NavText fontFamily="Britanica-Black">
                    {<FormattedMessage id="navigation.signin" />}
                  </NavText>
                </StyledNavLink>
              );
            } else {
              return (
                <>
                  {/* {isSubscribed && (
                    <StyledNavLink to="/inputpage">
                      <NavText fontFamily="Britanica-Black">
                        Custom Input
                      </NavText>
                    </StyledNavLink>
                  )} */}
                  {isSubscribed && (
                    <StyledNavLink to="/inputpage">
                      <NavText fontFamily="Britanica-Black">
                        Custom Input
                      </NavText>
                    </StyledNavLink>
                  )}
                  <StyledNavLink to="/account">
                    <NavText fontFamily="Britanica-Black">
                      {<FormattedMessage id="navigation.account" />}
                    </NavText>
                  </StyledNavLink>
                  <Button
                    mt="2px"
                    ml="20px"
                    fontSize="14px"
                    variant="link"
                    onClick={() => {
                      signOut(auth)
                        .then(() => {
                          console.log('sign out successful');
                          dispatch(setUuid(''));
                          dispatch(setIsSubscribed(false));
                          dispatch(setSubscriptionId(''));

                          navigate('/');
                          // Sign-out successful.
                        })
                        .catch((error) => {
                          console.log('error loggin out', error);
                          // An error happened.
                        });
                    }}
                  >
                    <NavText fontSize="18px" fontFamily="Britanica-Black">
                      Sign Out
                    </NavText>
                  </Button>
                </>
              );
            }
          })()}
          <Spacer />
          {(() => {
            // User is loged in and subscribed
            if ((isSubscribed && data?.uid) || !isDesktop) {
              return <></>;
            }

            // User login in but not subscribed
            if (!isSubscribed && data?.uid) {
              return (
                <Flex justifyContent="center" mt="20px" mb="15px">
                  <Button
                    borderRadius="full"
                    bg="#FFDA2A"
                    fontSize="14px"
                    minW="160px"
                    h="33px"
                    // alignSelf="center"
                    // minH="45px"
                    mb="5px"
                    mr="7px"
                    isLoading={loading}
                    _hover={{
                      bg: '#FFC107', // Slightly darker yellow on hover
                      // transform: "scale(1.05)", // Slightly enlarge the button on hover
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Add a subtle shadow on hover
                    }}
                    transition="all 0.3s ease"
                    onClick={() => {
                      setLoading(true);
                      handleSubscribe(data).then(() => setLoading(false));
                    }}
                  >
                    Get Unlimited Access
                  </Button>
                </Flex>
              );
            }

            return (
              <Flex
                justifyContent="center"
                mt="0px"
                mb="0px"
                mr={isDesktop ? '10px' : 'unset'}
              >
                <Button
                  borderRadius="full"
                  bg="#FFDA2A"
                  fontSize="14px"
                  minW="140px"
                  h="30px"
                  mr="7px"
                  _hover={{
                    bg: '#CEB021', // Slightly darker yellow on hover
                    // transform: 'scale(1.05)', // Slightly enlarge the button on hover
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Add a subtle shadow on hover
                  }}
                  transition="all 0.3s ease"
                  onClick={() => navigate('/signup')}
                >
                  Start Free Now
                </Button>
                <Button
                  borderRadius="full"
                  variant="outline"
                  color="white"
                  fontSize="14px"
                  minW="140px"
                  ml="7px"
                  h="30px"
                  // minH="45px"
                  // py="10px"
                  _hover={{
                    color: '#FFDA2A', // Change text color to a brighter yellow on hover
                    borderColor: '#FFDA2A', // Change the border to a brighter yellow on hover
                    // transform: "scale(1.05)", // Slightly enlarge the button on hover
                  }}
                  transition="all 0.3s ease"
                  isLoading={loading}
                  onClick={() => navigate('/pricing')}
                >
                  Pricing
                </Button>
              </Flex>
            );
          })()}
        </Show>
        <Hide breakpoint="(min-width: 400px)">
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent bg="black">
              <DrawerCloseButton style={{ color: 'white' }} />

              <DrawerBody>
                <StyledNavLink to="/home" onClick={onClose}>
                  <NavText>{<FormattedMessage id="navigation.home" />}</NavText>
                </StyledNavLink>
                <StyledNavLink to="/faq" onClick={onClose}>
                  <NavText>FAQ</NavText>
                </StyledNavLink>
                {(() => {
                  if (!data) {
                    return (
                      <StyledNavLink to="/signin" onClick={onClose}>
                        <NavText>
                          {<FormattedMessage id="navigation.signin" />}
                        </NavText>
                      </StyledNavLink>
                    );
                  } else {
                    return (
                      <>
                        {/* {isSubscribed && (
                          <StyledNavLink to="/inputpage" onClick={onClose}>
                            <NavText>Custom Input</NavText>
                          </StyledNavLink>
                        )} */}
                        {isSubscribed && (
                          <StyledNavLink to="/inputpage" onClick={onClose}>
                            <NavText>Custom Input</NavText>
                          </StyledNavLink>
                        )}
                        <StyledNavLink to="/account" onClick={onClose}>
                          <NavText>
                            {<FormattedMessage id="navigation.account" />}
                          </NavText>
                        </StyledNavLink>
                        <Button
                          mt="5px"
                          ml="20px"
                          fontSize="14px"
                          variant="link"
                          onClick={() => {
                            signOut(auth)
                              .then(() => {
                                console.log('sign out successful');
                                dispatch(setUuid(''));
                                dispatch(setIsSubscribed(false));
                                dispatch(setSubscriptionId(''));
                                onClose();
                                navigate('/');
                                // Sign-out successful.
                              })
                              .catch((error) => {
                                console.log('error loggin out', error);
                                // An error happened.
                                onClose();
                              });
                          }}
                        >
                          <NavText fontSize="18px">Sign Out</NavText>
                        </Button>
                      </>
                    );
                  }
                })()}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
          {/* <Tag colorScheme="orange" ml="2">Beta</Tag> */}
          <Image src={SLMLogo} h="25px" ml="5px" mr="-5" mb="-3px" />
          <IconButton
            ref={btnRef}
            aria-label="Menu"
            h="15"
            w="15"
            borderLeftRadius="none"
            ml="auto"
            borderRightRadius="full"
            bg="transparent"
            justifyContent="center"
            _hover={{ bg: 'transparent' }}
            onClick={onOpen}
          >
            <Icon as={Menu} color="#9e9e9e" boxSize="21" mr="0" />
          </IconButton>
        </Hide>
      </HeaderWrapper>
      <Box
        as={Collapse}
        in={pathname === '/explore' || pathname === '/upload'}
        w="100%"
        /* To prevent datepicker box cut off */
        style={{
          overflow: 'visible',
        }}
      >
        <HeaderWrapper
          borderTopWidth="1px"
          borderTopColor="#ebebeb"
          zIndex="99"
          pl="18px"
        >
          {/* <SearchBarGeoCoder /> */}
        </HeaderWrapper>
      </Box>
      <Flex
        w="100%"
        h="40px"
        bg="black"
        justifyContent="space-evenly"
        alignItems="center"
        p="15px"
      >
        {isDesktop && (
          <Text color="white" fontSize="14px">
            <strong>25% OFF FOREVER for the first 1000 subscribers:</strong> use
            code WELCOME25
          </Text>
        )}
        <Text color="white" fontSize="14px">
          {isDesktop ? <strong>Contribution Rewards:</strong> : ''} A free month
          for every 25 added sequences
        </Text>
      </Flex>
    </>
  );
};

export default Header;
